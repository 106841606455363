
// export const environment = {
//   checkSignalrDevApiUrl: 'https://api.fonrisk.com/fonrisk/CheckPending',

//   baseURL:'https://api.fonrisk.com/fonrisk/api',

//   allowedDomainsDEVApi: 'api.fonrisk.com/fonrisk',
// };
// export const environment = {
//   checkSignalrDevApiUrl: 'https://localhost:44372/CheckPending',

//   baseURL:'https://localhost:44372/api',

//   allowedDomainsDEVApi: 'localhost:44372',
// };
export const environment = {
  checkSignalrDevApiUrl: 'http://apitest.fonrisk.com/fonrisk/CheckPending',

  baseURL:'http://apitest.fonrisk.com/fonrisk/api',

  allowedDomainsDEVApi: 'apitest.fonrisk.com/fonrisk',
};
// let checkSignalrDevApiUrl= 'http://192.168.1.44:44371/CheckPending'
// let baseURL='http://192.168.1.44:44371/api'
// let allowedDomainsDEVApi="192.168.1.44:44371"
// export const environment = {
//   checkSignalrDevApiUrl: `${checkSignalrDevApiUrl}`,

//   baseURL:`${baseURL}`,

//   allowedDomainsDEVApi: `${allowedDomainsDEVApi}`,
// };
